





































import Vue from 'vue';
import {Component} from 'vue-property-decorator';
import RadioGroup from '@/components/RadioGroup.vue';
import Button from '@/components/Button.vue';
import Checkbox from '@/components/Checkbox.vue';
import Input from '@/components/Input.vue';
import Step1 from '@/components/steps/Step1.vue';
import Step2 from '@/components/steps/Step2.vue';
import Step3 from '@/components/steps/Step3.vue';
import Summary from '@/components/steps/Summary.vue';

const STEP_TITLES = [
  'Choose your pizza size',
  'Choose toppings',
  'Customer details',
  'Summary'
];

@Component({
  components: {Summary, Step3, Step2, Step1, Input, Checkbox, Button, RadioGroup},
})
export default class Order extends Vue {
  public currentStep = 1;

  public get stepTitle(): string {
    return STEP_TITLES[this.currentStep - 1];
  }

  public nextStep(): void {
    if (this.currentStep === 4) return;
    this.currentStep += 1;
  }

  public prevStep(): void {
    if (this.currentStep === 1 || this.currentStep === 4) return;
    this.currentStep -= 1;
  }
}
