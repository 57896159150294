



























import {Component, Mixins, Prop} from 'vue-property-decorator';
import ValidationMixin from '@/mixins/validation-mixin';
import ErrorMessage from '@/components/ErrorMessage.vue';

@Component({
  components: {ErrorMessage}
})
export default class BaseInput extends Mixins(ValidationMixin) {
  @Prop({required: true})
  public value!: string;

  @Prop({required: true})
  public id!: string;

  @Prop({required: true})
  public label!: string;

  @Prop({required: true})
  public placeholder!: string;

  public get modelValue(): string {
    return this.value;
  }

  public set modelValue(value: string) {
    this.validateField();
    this.$emit('input', value);
  }

  public onBlur(event: Event): void {
    this.touched = true;
    this.validateField();
    this.$emit('blur', event);
  }
}
