export enum Topping {
  Mushrooms = 'Mushrooms',
  Olives = 'Olives',
  Tomato = 'Tomato',
  Tona = 'Tona',
  Pineapple = 'Pineapple',
  Seafood = 'Seafood',
  Pepperoni = 'Pepperoni',
  Bacon = 'Bacon',
  Onion = 'Onion',
  Mozzarella = 'Mozzarella',
}
