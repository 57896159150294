













import {Component, Prop} from 'vue-property-decorator';
import Vue from 'vue';
import {Size} from '@/models/size';

@Component({
  data: () => ({
    Size,
  })
})
export default class PizzaImage extends Vue {
  @Prop({type: String, required: true})
  public size!: Size;
}
