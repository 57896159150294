



















































import Vue from 'vue';
import Component from 'vue-class-component';
import {Size} from '@/models/size';
import {SizePrice} from '@/models/size-price';
import {Topping} from '@/models/topping';
import {ToppingPrice} from '@/models/topping-price';
import PizzaImage from '@/components/PizzaImage.vue';
import Button from '@/components/Button.vue';

@Component({
  components: {Button, PizzaImage},
  data: () => ({
    Size,
    SizePrice,
  })
})
export default class Summary extends Vue {
  public vat = 0;
  public totalPrice = 0;

  public mounted(): void {
    this.countPrice();
  }

  public get pizzaSize(): Size {
    return this.$store.state.currentOrder.size;
  }

  public get toppings(): Topping[] {
    return this.$store.state.currentOrder.toppings.join(', ').toLowerCase();
  }

  public get name(): string {
    return this.$store.state.currentOrder.customer.name;
  }

  public get address(): string {
    return this.$store.state.currentOrder.customer.address;
  }

  public get phone(): string {
    return this.$store.state.currentOrder.customer.phone;
  }

  public countPrice(): void {
    const sizePrice = SizePrice[this.pizzaSize];
    const toppings = this.$store.state.currentOrder.toppings as Topping[];
    let toppingsPrices = toppings.map((topping) => ToppingPrice[topping]);
    console.log(toppingsPrices)
    const howManyFreeToppings = Math.floor(toppingsPrices.length / 3);
    console.log({...toppingsPrices}, howManyFreeToppings)
    toppingsPrices = toppingsPrices.sort().slice(howManyFreeToppings);
    console.log(toppingsPrices);
    const toppingTotalPrice = toppingsPrices.reduce((total, price) => {
      total += price;
      return total;
    }, 0);

    let tempTotal = sizePrice + toppingTotalPrice;

    this.vat = Math.floor(tempTotal * 0.15 * 100) / 100;
    this.totalPrice = tempTotal + this.vat;
  }

  public reload(): void {
    const newUrl = new URL(window.location.href);
    window.location.href = newUrl.href;
  }
}
