














































import Vue from 'vue';
import {Component} from 'vue-property-decorator';
import RadioGroup from '@/components/RadioGroup.vue';
import Button from '@/components/Button.vue';
import Checkbox from '@/components/Checkbox.vue';
import {isValidPhoneNumber} from 'libphonenumber-js';
import Input from '@/components/Input.vue';

@Component({
  components: {Input, Checkbox, Button, RadioGroup},
})
export default class Step3 extends Vue {
  public name = this.$store.state.currentOrder.customer.name || '';
  public address = this.$store.state.currentOrder.customer.name || '';
  public phone = this.$store.state.currentOrder.customer.name || '';
  public hasErrors: boolean[] = [];

  public validateName(value: string): string {
    if (!value || !value.trim()) return 'You should fill the name';
    return '';
  }

  public validateAddress(value: string): string {
    if (!value || !value.trim()) return 'You should fill the address';
    if (value.trim().length < 10) return 'The address is too short (min 10 symbols)';
    return '';
  }

  public validatePhone(value: string): string {
    if (!value || !value.trim()) return 'You should fill the phone';
    if (!isValidPhoneNumber(value)) return 'The phone is invalid';
    return '';
  }

  public setError(event: {hasError: boolean, message: string}): void {
    this.hasErrors.push(event.hasError);
  }

  public prevStep(): void {
    this.$emit('prev-step');
  }

  public nextStep(): void {
    this.hasErrors = [];
    (this.$refs.nameInput as any).onBlur();
    (this.$refs.addressInput as any).onBlur();
    (this.$refs.phoneInput as any).onBlur();
    if (this.hasErrors.filter(Boolean).length) return;
    this.$store.commit('currentOrder/updateCustomer', {
      name: this.name,
      address: this.address,
      phone: this.phone,
    });
    this.$emit('next-step');
  }
}

