






















import IconTick from './IconTick.vue';
import {Component, Prop} from 'vue-property-decorator';
import Vue from 'vue';

@Component({
  components: {
    IconTick,
  },
})
export default class Checkbox extends Vue {
  @Prop({type: String, default: null})
  public label?: string;

  @Prop({type: Boolean, default: false})
  public readonly?: boolean;

  @Prop({type: String, default: null})
  public title?: string;

  @Prop({type: String, required: true})
  public context!: string;

  @Prop({type: Boolean, required: true})
  public value!: boolean;

  public innerValue = false;

  public mounted(): void {
    this.innerValue = this.value;
  }

  public checkChanged(): void {
    if (this.readonly) {
      return;
    }

    this.innerValue = !this.innerValue;
    this.$emit('input', this.innerValue, this.context);
    this.$emit('change');
  }
}
