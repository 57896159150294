
















import Vue, {PropType} from 'vue';
import {Component, Prop, Watch} from 'vue-property-decorator';
import Radio from '@/components/Radio.vue';
import {RadioOption} from '@/components/radio-option';

@Component({
  components: {Radio}
})
export default class RadioGroup extends Vue {
  @Prop({type: [Number, String], required: true})
  public value!: string | number;

  @Prop({type: Array as PropType<RadioOption[]>, required: true})
  public options!: RadioOption[];

  public innerValue: string | number = '';

  public mounted(): void {
    this.setInitialValue();
  }

  setNewOptionValue(optionValue: string): void {
    this.innerValue = optionValue;
    this.$emit('input', this.innerValue);
    this.$emit('change');
  }

  @Watch('value')
  public setInitialValue(): void {
    this.innerValue = this.value;
  }
}
