















import {Component, Prop} from 'vue-property-decorator';
import Vue from 'vue';

@Component({})
export default class ErrorMessage extends Vue {
  @Prop()
  public error?: string;
}
